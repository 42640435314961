$midnight-blue: #425160;
$slate-gray: #5c6875;
$charcoal: #313941;
$eerie-black: #202020;
$raisin-black: #181818;
$jet: #303030;
$jet-black: #131414;
$cadet-blue: #aeb1bd;
$lust-red: #e40521;
$amaranth: #e22e2e;
$salmon: #fa7e7e;
$salmon-20-opacity: #fa7e7e33;
$salmon-10-opacity: #fda8a811;
$silver-chalice: #afafaf;
$sonic-silver: #7c7c7c;
$granite-gray: #686d71;
$granite-gray-10-opacity: #686d7133;
$light-gray: #d3d3d3;
$white: #ffffff;
$isabelline: #efefef;
$cultured: #fafafa;
$onyx: #343434;
$onyx-80-opacity: #343434cc;
$sky-blue: #80c9ff;
$tufts-blue: #4f9edd;
$american-green: #23b33a;
$gray: #808080;
$tufts-blue-20-opacity: #4f9edd33;
$violet-blue: #3753b1;
$white-40-opacity: #ffffff66;
$black: #000000;
$black-25-opacity: #00000040;
$black-30-opacity: #0000004c;
$black-60-opacity: #00000099;
$eerie-black-25-opacity: #20202040;
$eerie-black-45-opacity: #20202072;
$eerie-black-50-opacity: #20202079;
$eerie-black-40-opacity: #20202066;
$white-80-opacity: #ffffffcc;
$marigold: #e9a635;
$whisper: #f1f1f1;
$granite-gray-12-opacity: #686d711f;
$sonic-silver-60-opacity: #7c7c7c99;
$tufts-blue-12-opacity: #4f9edd1f;
$transparent-white: #ffffff00;
$silver: #c4c4c4;
