
      @import "variables/_colors.scss";
      @import "variables/_fontFamily.scss";
    
@import "./utils/components/_bannerSlide";
@import "./utils/variables/_titles";
@import "./utils/variables/_buttons";
@import "./utils/components/_gallerySlide";
@import "./utils/variables/fontFamily";

@font-face {
  font-family: 'ToyotaType';
  src: url('/fonts/TOYOTATYPE-REGULAR.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.CMS {
  color: $eerie-black;
  text-align: center;
  font-family: "CMS BYD" !important;
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 400;

  transform: rotate(0deg);
  transition: all 0.3s ease;

  &.rotate {
    transform: rotate(180deg);
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}
* {
  scroll-behavior: smooth !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}
@font-face {
  font-family: "ToyotaType";
  src: url("https://cdn.designsystemtoyota.com.br/font/ToyotaType-Regular.woff2")
      format("woff2"),
    url("https://cdn.designsystemtoyota.com.br/font/ToyotaType-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ToyotaType";
  src: url("https://cdn.designsystemtoyota.com.br/font/ToyotaType-Bold.woff2")
      format("woff2"),
    url("https://cdn.designsystemtoyota.com.br/font/ToyotaType-Bold.woff")
      format("woff");
  font-weight: bold;
  font-style: normal;
}
* {
  font-family: "ToyotaType", sans-serif !important;
}
body {
  color: white;
  background-color: rgba(245 245 245);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Slick CSS */
.customDotsGap .slick-dots {
  gap: 2rem;
}
.slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
}
.slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.customSlickFlex .slick-track {
  display: flex;
  align-items: end;
}

/* Divs Animation CSS */
.divHover:hover .up-div {
  padding-bottom: 4rem;
}
.divHover:hover .paint-button {
  background-color: #969696;
  color: white;
}
.divHover:hover .paint-button-alternative {
  background-color: white;
  color: black;
}
.divHover:hover .show {
  display: block;
  opacity: 1;
}
.divHover:hover .enlargeImage {
  height: 220px;
}

/* Forms CSS */
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
}

.checkboxGroup input:checked ~ .checkboxMark {
  background: red;
  border: white solid 3px !important;
}
.checkboxGroup input:checked ~ .checkboxMark:before {
  content: " ";
  position: relative;
  z-index: 1;
  top: -6px;
  left: -5px;
  right: 0px;
  bottom: 0px;
  padding: 0px 9px;
  border-radius: 100%;
  border: 2px solid red;
}
.checkboxGroup input:hover ~ .checkboxMark:before {
  content: " ";
  position: relative;
  z-index: 1;
  top: -6px;
  left: -5px;
  right: 0px;
  bottom: 0px;
  padding: 0px 9px;
  border-radius: 100%;
  border: 2px solid red;
}
.checkboxGroup:hover input ~ .checkboxMark {
  background: red;
  border: white solid 3px !important;
}

.selectedCar {
  filter: grayscale(100%) sepia(100%) brightness(190%) hue-rotate(133deg)
    saturate(1000%) contrast(183%) invert(100%);
  -webkit-filter: grayscale(100%) sepia(100%) brightness(190%)
    hue-rotate(133deg) saturate(1000%) contrast(183%) invert(100%);
  -moz-filter: grayscale(100%) sepia(100%) brightness(190%) hue-rotate(133deg)
    saturate(1000%) contrast(183%) invert(100%);
}

.carButton:hover {
  cursor: pointer;
  transition: all 0.3s;
  filter: grayscale(100%) brightness(70%) saturate(1000%) contrast(183%);
  -webkit-filter: grayscale(100%) brightness(70%) saturate(1000%) contrast(183%);
  -moz-filter: grayscale(100%) brightness(70%) saturate(1000%) contrast(183%);
}

.defaultColor {
  filter: sepia(0%) brightness(0%) hue-rotate(312deg) contrast(10) saturate(0%);
  -webkit-filter: sepia(0%) brightness(0%) hue-rotate(312deg) contrast(10)
    saturate(0%);
  -moz-filter: sepia(0%) brightness(0%) hue-rotate(312deg) contrast(10)
    saturate(0%);
}
.sportiveColor {
  filter: sepia(100%) brightness(500%) hue-rotate(312deg) contrast(2)
    saturate(1000%);
  -webkit-filter: sepia(100%) brightness(500%) hue-rotate(312deg) contrast(2)
    saturate(1000%);
  -moz-filter: sepia(100%) brightness(500%) hue-rotate(312deg) contrast(2)
    saturate(1000%);
}
.hibridColor {
  filter: sepia(100%) brightness(356%) hue-rotate(195deg) contrast(1.5)
    saturate(1000%);
  -webkit-filter: sepia(100%) brightness(356%) hue-rotate(195deg) contrast(1.5)
    saturate(1000%);
  -moz-filter: sepia(100%) brightness(356%) hue-rotate(195deg) contrast(1.5)
    saturate(1000%);
}
.otherColor {
  filter: sepia(100%) brightness(143%) hue-rotate(198deg) contrast(1.5)
    saturate(1000%);
  -webkit-filter: sepia(100%) brightness(143%) hue-rotate(198deg) contrast(1.5)
    saturate(1000%);
  -moz-filter: sepia(100%) brightness(143%) hue-rotate(198deg) contrast(1.5)
    saturate(1000%);
}

.message-enter {
  opacity: 0;
  transform: translateY(20px);
}

.message-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.message-exit {
  opacity: 1;
}

.message-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 300ms, transform 300ms;
}

.chat-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.chat-container::-webkit-scrollbar {
  width: 6px;
}

.chat-container::-webkit-scrollbar-track {
  background: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
}
.chat-container {
  -webkit-overflow-scrolling: touch; /* Para scroll suave no iOS */
  scrollbar-width: thin;
  max-height: calc(100vh - 200px); /* Ajuste conforme necessário */
}

@media (max-width: 1024px) {
  .chat-container {
    max-height: calc(
      100dvh - 250px
    ); /* Ajuste para mobile considerando o header e área de input */
  }
}

.customDotsGap .slick-dots {
  display: flex !important;
  justify-content: center;
  gap: 8px;
  bottom: 16px;
  padding: 0 16px;
  flex-wrap: wrap;
}

.customDotsGap .slick-dots li {
  margin: 0;
  width: auto;
}

@media (max-width: 768px) {
  .customDotsGap .slick-dots li div {
    width: 24px !important;
  }
}
